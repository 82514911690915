import React from "react";
import Skeleton from "react-loading-skeleton";
/* <Skeleton style={{ position: "absolute", top: 0, height: "100%", opacity: 0.5, overflow: "hidden" }} /> */
const Response = (props) => {
	return (
		<div className='response container'>
			<div className='rating'>
				<div
					className={
						props.data.rating <= 6 ? "score detractor" : props.data.rating <= 8 ? "score passive" : "score promoter"
					}
				>
					{props.data.rating}
				</div>
			</div>
			<div className='info-wrapper'>
				<div className='user'>
					{props.data.respondent.firstName === "" ? "Anonymous" : props.data.respondent.firstName}
				</div>
				<div className='comment'>
					{props.data.isFlagged === true && props.data.userComment !== null ? (
						<p className='flagged-comment'>This comment is hidden.</p>
					) : props.data.userComment === null ? (
						<em>...</em>
					) : (
						props.data.userComment
					)}
				</div>
				<div className='date'>{new Date(props.data.ratingTime).toLocaleDateString("da-DK")}</div>
			</div>

			{props.isLoading && <Skeleton className='skeleton' />}
		</div>
	);
};

export default Response;
