import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer>
			<div className='background-full-width'>
				<div className='content-wrapper footer'>
					<div className='company'>
						<div className='logo' />
						<div className='company-info'>
							<p>nps.today</p>
							<span>Højbro Plads 10</span>
							<span>1200 København K</span>
						</div>
						<div className='copyright'>
							<p>{t("footer.copyright")}</p>
						</div>
						<ul className='footer-menu'>
							<li className='footer-menu-item'>
								<a
									target='_blank'
									rel='noopener noreferrer'
									href={"https://nps.today/en/cookies-and-privacy-policy-eng/"}
								>
									{t("footer.cookiesAndPrivacy")}
								</a>
							</li>
							<li className='footer-menu-item'>
								<a target='_blank' rel='noopener noreferrer' href={"https://nps.today/en/business-conditions-eng/"}>
									{t("footer.termsAndConditions")}
								</a>
							</li>
						</ul>
					</div>
					<div className='social-media'>
						{/*
						<a
							className='brand-icon'
							href={"https://www.facebook.com/nps.today"}
							target={"_blank"}
							rel='noopener noreferrer'
						>
							<FontAwesomeIcon size='2x' icon={["fab", "facebook"]} />
						</a>
						<a
							className='brand-icon'
							href={"https://www.youtube.com/c/NpsToday-company"}
							target={"_blank"}
							rel='noopener noreferrer'
						>
							<FontAwesomeIcon size='2x' icon={["fab", "youtube"]} />
						</a>
						*/}
						<a
							className='brand-icon'
							href={"https://www.linkedin.com/company/npstoday/"}
							target={"_blank"}
							rel='noopener noreferrer'
						>
							<FontAwesomeIcon size='2x' icon={["fab", "linkedin"]} />
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
