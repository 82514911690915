import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOrgIdByReviewName } from "../integrations/orgIdByReviewName";
import { getCompanyProfile } from "../integrations/organisationDetails";
import { getResponsesByOrgId } from "../integrations/responsesByorgId";
import { getSearch } from "../integrations/Search";
import { isDev } from "../util/isDev";

export const RESPONSES_TAKE = 10;
const initialState = {
	isFrontPage: true,
	organisationProfile: null,
	responses: null,
	responsesSkip: 0,
	organizationNames: null,
	isLoading: { changePage: false },
	hasError: { changePage: false },
};

const ReviewContext = createContext(initialState);

function ReviewProvider({ children }) {
	const [isFrontPage, setIsFrontPage] = useState(initialState.isFrontPage);
	const [organisationProfile, setOrganisationProfile] = useState(initialState.organisationProfile);
	const [reviewHeaderSrc, setReviewHeaderSrc] = useState(null);
	const [responses, setResponses] = useState(initialState.responses);
	const [responsesToSkip, setResponsesToSkip] = useState(initialState.responsesSkip);
	const [organizationNames, setOrganizationNames] = useState(initialState.organizationNames);
	const [isLoading, setIsLoading] = useState({});
	const [hasError, setHasError] = useState({});

	useEffect(() => {
		const prepareSearch = async () => {
			const organisations = await getSearch();
			setOrganizationNames(organisations);
		};
		prepareSearch();
	}, []);

	const resetReviewPage = () => {
		setReviewHeaderSrc(null);
		setOrganisationProfile(initialState.organisationProfile);
		setResponses(initialState.responses);
		setResponsesToSkip(0);

		setHasError((prevState) => {
			return { ...prevState, orgIdDoesNotExist: false };
		});
	};

	const loadResponses = useCallback(async (skip, currentOrgId) => {
		if (!currentOrgId) return;

		try {
			setIsLoading((prevState) => {
				return { ...prevState, responses: true };
			});

			const newResponses = await getResponsesByOrgId(currentOrgId, skip);
			setResponsesToSkip(skip);
			setResponses(newResponses);

			setHasError((prevState) => {
				return { ...prevState, responses: false };
			});
		} catch (error) {
			setHasError((prevState) => {
				return { ...prevState, responses: true };
			});
		} finally {
			setIsLoading((prevState) => {
				return { ...prevState, responses: false };
			});
		}
	}, []);

	const changePage = useCallback(
		async (reviewName) => {
			setIsFrontPage(!reviewName);
			resetReviewPage();

			try {
				setIsLoading((prevState) => {
					return { ...prevState, changePage: true, profile: true, responses: true };
				});

				const fetchedOrgId = await getOrgIdByReviewName(reviewName);
				if (!fetchedOrgId) {
					setHasError((prevState) => {
						return { ...prevState, orgIdDoesNotExist: true };
					});
					throw new Error("No orgId found");
				}

				setReviewHeaderSrc(
					`${
						isDev()
							? "https://npstodaydevelopment.blob.core.windows.net/nps-widget/reviewboxes/"
							: "https://widget.nps.today/reviewboxes/"
					}${fetchedOrgId}/review_header.html`
				);

				const reflect = (promise, statusKey = 123) =>
					promise.then(
						(value) => {
							setHasError((prevState) => {
								const newState = { ...prevState };
								newState[statusKey] = false;
								return newState;
							});
							return { status: "fulfilled", value };
						},
						(error) => {
							setHasError((prevState) => {
								const newState = { ...prevState };
								newState[statusKey] = true;
								return newState;
							});
							return { status: "rejected", error };
						}
					);

				const results = await Promise.all([
					reflect(getCompanyProfile(fetchedOrgId), "profile"),
					reflect(loadResponses(0, fetchedOrgId), "responses"),
				]);

				const fetchedCompanyProfile = results[0].value ?? null;

				setOrganisationProfile(fetchedCompanyProfile);

				setHasError((prevState) => {
					return { ...prevState, changePage: false };
				});
			} catch (error) {
				setHasError((prevState) => {
					return { ...prevState, changePage: true };
				});
			} finally {
				setIsLoading((prevState) => {
					return { ...prevState, changePage: false, profile: false, responses: false };
				});
			}
		},
		[loadResponses]
	);

	const location = useLocation();
	useEffect(() => {
		const regex = /\/r\/?([^/]*)/;
		const match = location.pathname.match(regex);
		const newReviewName = match && match[1] ? match[1] : null;

		changePage(newReviewName);
	}, [location.pathname, changePage]);

	return (
		<ReviewContext.Provider
			value={{
				isFrontPage,
				organisationProfile,
				reviewHeaderSrc,
				responses,
				loadResponses,
				responsesToSkip,
				organizationNames,
				isLoading,
				hasError,
			}}
		>
			{children}
		</ReviewContext.Provider>
	);
}

export { ReviewProvider, ReviewContext };
