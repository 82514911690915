import React, { Component, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core"; //allows later to just use icon name to render-them
import { fab } from "@fortawesome/free-brands-svg-icons";

// Styling
import "./assets/scss/app.scss";

// Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

// Routing
import Content from "./components/Routing/Routing";
import { ReviewProvider } from "./contexts/ReviewContext";
import { reactPlugin, setupAppInsights } from "./appInsisghts";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

library.add(fab);

setupAppInsights();

class App extends Component {
	render() {
		return (
			<AppInsightsContext.Provider value={reactPlugin}>
				<Suspense fallback={<></>}>
					<Router>
						<ReviewProvider>
							<Header />
							<Content />
							<Footer />
						</ReviewProvider>
					</Router>
				</Suspense>
			</AppInsightsContext.Provider>
		);
	}
}

export default App;
