import React, { useEffect } from "react";

// Components
import Analytics from "../components/Analytics/Analytics";
import ResponseContainer from "../components/Response/ResponseContainer";
import Sidebars from "../components/Sidebars/Sidebars";
import { ReviewContext } from "../contexts/ReviewContext";
import { useContext } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

function Review() {
	const { hasError, organisationProfile } = useContext(ReviewContext);

	//log each review page view
	const appInsights = useAppInsightsContext();
	useEffect(() => {
		const reviewName = organisationProfile?.reviewName;
		if (reviewName) {
			appInsights?.trackPageView({ name: reviewName, orgId: organisationProfile?.id });
		}
	}, [appInsights, organisationProfile]);

	return (
		<>
			<div className='content-wrapper'>
				{!hasError.orgIdDoesNotExist && (
					<>
						<main id='review'>
							<Analytics />
							<ResponseContainer />
						</main>
						<aside>
							<Sidebars />
						</aside>
					</>
				)}
			</div>
			<div style={{ flex: 1 }} />
		</>
	);
}

export default Review;
