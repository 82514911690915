import Skeleton from "react-loading-skeleton";
import { useContext, useEffect, useState } from "react";
import { ReviewContext } from "../../contexts/ReviewContext";
import { useTranslation } from "react-i18next";

function Analytics() {
	const { i18n } = useTranslation();
	const { reviewHeaderSrc } = useContext(ReviewContext);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
	}, [reviewHeaderSrc]);

	useEffect(() => {
		window.addEventListener(
			"message",
			function (event) {
				if (event.data === "review_header_loaded") {
					setIsLoading(false);
				}
			},
			false
		);
	}, []);

	return (
		<div id='analytics'>
			{reviewHeaderSrc && <iframe title='Review Header' src={`${reviewHeaderSrc}?language=${i18n.language}`} />}
			{isLoading && <Skeleton className='skeleton' />}
		</div>
	);
}

export default Analytics;
