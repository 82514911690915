import { useEffect, useState } from "react";
// @mui
import { MenuItem, Stack } from "@mui/material";
// components
import Image from "../../../components/Image";
import MenuPopover from "../../MenuPopover";
import IconButtonAnimate from "../../animate/IconButtonAnimate";
// config
import { availableLanguages, fallbackLanguage } from "../../../i18n";
import { useTranslation } from "react-i18next";
//styles
import "../../../assets/scss/components/_languagePopover.scss";
import languageIcon from "../../../assets/img/language.svg";

// ----------------------------------------------------------------------

export default function LanguagePopover() {
	const [open, setOpen] = useState(null);
	const [selectedLanguage, setSelectedLanguage] = useState(fallbackLanguage);
	const { i18n } = useTranslation();

	//on i18n ready, set selected language in dropdown to default language
	useEffect(() => {
		const language = availableLanguages?.find((language) => language.code === i18n?.language);
		setSelectedLanguage(language || fallbackLanguage);
	}, [i18n, setSelectedLanguage]);

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const onLanguageChange = (language) => () => {
		//set local value for Select object
		setSelectedLanguage(language);

		//change i18n language to translate site
		i18n.changeLanguage(language.code);

		//close popover
		setOpen(null);
	};

	return (
		<div className='language-popover-wrapper'>
			<IconButtonAnimate
				onClick={handleOpen}
				sx={{
					width: 40,
					height: 40,
					...(open && { bgcolor: "action.selected" }),
				}}
			>
				<Image className='language-img' disabledEffect src={languageIcon} alt={selectedLanguage?.nameInNative} />
			</IconButtonAnimate>
			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					mt: 1.5,
					ml: 0.75,
					width: "min-content",
					"& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
					overflow: "auto",
				}}
			>
				<Stack spacing={0.75}>
					{availableLanguages.map((option) => (
						<MenuItem
							key={option.code}
							selected={option.code === selectedLanguage?.code}
							onClick={onLanguageChange(option)}
						>
							{option?.nameInNative}
						</MenuItem>
					))}
				</Stack>
			</MenuPopover>
		</div>
	);
}
