import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { CONNECTION_STRING } from "./integrations/api";

export const reactPlugin = new ReactPlugin();
export const setupAppInsights = () => {
	if (!CONNECTION_STRING) return;
	const browserHistory = createBrowserHistory({ basename: "" });
	const appInsights = new ApplicationInsights({
		config: {
			connectionString: CONNECTION_STRING,
			autoTrackPageVisitTime: true,
			enableAjaxErrorStatusText: true,
			enableAjaxPerfTracking: true,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: browserHistory },
			},
		},
	});
	appInsights.loadAppInsights();
};
