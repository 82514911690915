import { RESPONSES_TAKE } from "../contexts/ReviewContext";
import { URL } from "./api";

export const getResponsesByOrgId = (orgId, skip) => {
	return fetch(URL + "/responses?orgId=" + orgId + "&skip=" + skip + "&take=" + RESPONSES_TAKE)
		.then((res) => {
			return res.json();
		})
		.then((json) => {
			return new Promise((resolve, reject) => {
				return resolve(json);
			});
		});
};
