import {URL} from "./api";

export const getSearch = () => {
  return fetch(URL + '/reviewNames')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return new Promise((resolve, reject) => {
          return resolve(json);
        });
      });
};