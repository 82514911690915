import React from "react";

// Components
import { useContext } from "react";
import { ReviewContext } from "../../contexts/ReviewContext";
import { useTranslation } from "react-i18next";

function Sidebars() {
	const { t } = useTranslation();
	const { organisationProfile } = useContext(ReviewContext);
	const companyName = organisationProfile?.reviewName;
	return (
		<div id='sidebars'>
			<div className='sidebar container'>
				<h3>{t("company.description.whatIs")}</h3>
				<p>{t("company.description.p1", { companyName: companyName })}</p>
				<p>{t("company.description.p2", { companyName: companyName })}</p>
				<p>
					{t("company.description.p3")}
					<a rel='noopener noreferrer' target='_blank' href={"https://nps.today/en/article/what-is-nps/"}>
						{t("company.description.readMoreAboutNpsLink")}
					</a>
				</p>
				<p>{t("company.description.p4", { companyName: companyName })}</p>
				<p>{t("company.description.p5")}</p>
			</div>
			{organisationProfile?.reviewDescription && (
				<div className='sidebar container'>
					<h3>
						{t("company.description.about")} {companyName}
					</h3>
					<div>{<div dangerouslySetInnerHTML={{ __html: organisationProfile.reviewDescription }} />}</div>
				</div>
			)}
		</div>
	);
}

export default Sidebars;
