import React, { useRef } from "react";
import Skeleton from "react-loading-skeleton";

// Components
import Response from "./Response";
import { useContext } from "react";
import { ReviewContext } from "../../contexts/ReviewContext";
import Pagination from "./Pagination";

const ResponseContainer = () => {
	const containerRef = useRef(null);
	const { responses, isLoading } = useContext(ReviewContext);

	return (
		<>
			<div ref={containerRef} className='scroll-point' />
			<div id='responsesContainer'>
				{responses === null ? (
					<Skeleton height={150} />
				) : (
					responses.results.map((data, id) => {
						return <Response key={id} data={data} isLoading={isLoading.responses} />;
					})
				)}
			</div>
			{responses && <Pagination containerRef={containerRef} />}
		</>
	);
};

export default ResponseContainer;
