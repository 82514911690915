import React from "react";
import { useContext } from "react";
import { RESPONSES_TAKE, ReviewContext } from "../../contexts/ReviewContext";
import { Pagination as PaginationComponent } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const Pagination = ({ containerRef }) => {
	const { responses, responsesToSkip, organisationProfile, loadResponses, isLoading } = useContext(ReviewContext);
	const [paginationSize, setPaginationSize] = useState("medium");
	const [isPaginationUsed, setIsPaginationUsed] = useState(false);

	useEffect(() => {
		function updateSize() {
			const width = window.innerWidth;
			setPaginationSize(width <= 576 ? "small" : width <= 768 ? "medium" : "large");
		}

		// Update size on mount
		updateSize();

		// Add resize event listener
		window.addEventListener("resize", updateSize);

		// Cleanup
		return () => {
			window.removeEventListener("resize", updateSize);
		};
	}, []);

	useEffect(() => {
		if (!isLoading.responses && isPaginationUsed) {
			const isTopInView = containerRef.current.getBoundingClientRect().top >= 0;
			if (!isTopInView) {
				containerRef.current.scrollIntoView({
					behavior: "smooth", // smooth scrolling
				});
			}
		}
	}, [isLoading, isPaginationUsed, containerRef]);

	const getNewSkip = (page) => {
		return (page - 1) * RESPONSES_TAKE;
	};
	return (
		<PaginationComponent
			size={paginationSize}
			className='container pagination'
			count={responses?.total ? Math.ceil(responses?.total / RESPONSES_TAKE) : 1}
			page={responsesToSkip / RESPONSES_TAKE + 1}
			variant='outlined'
			shape='rounded'
			onChange={(_, page) => {
				setIsPaginationUsed(true);
				loadResponses(getNewSkip(page), organisationProfile?.id);
			}}
		/>
	);
};

export default Pagination;
