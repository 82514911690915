import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

// Method
import { withTranslation } from "react-i18next";
import { useContext } from "react";
import { ReviewContext } from "../../contexts/ReviewContext";
import { useState } from "react";

const Search = (props) => {
	const { organizationNames } = useContext(ReviewContext);
	const [searchResult, setSearchResult] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(-1); // For tracking the selected suggestion
	const searchFieldRef = useRef(null);

	const searchHandler = (e) => {
		const searchValue = e.target.value.toLowerCase();
		if (!searchValue) {
			setSearchResult(null);
		} else {
			const result = organizationNames?.filter((organizationName) => {
				return organizationName.toLowerCase().includes(searchValue);
			});
			setSearchResult(result);
		}
	};

	const clearSearchInput = () => {
		searchFieldRef.current.value = "";
		setSearchResult(null);
	};

	const onKeyDownHandler = (e) => {
		if (e.key === "ArrowDown" && selectedIndex < searchResult.length - 1) {
			setSelectedIndex((prev) => prev + 1);
		} else if (e.key === "ArrowUp" && selectedIndex > 0) {
			setSelectedIndex((prev) => prev - 1);
		} else if (e.key === "Enter" && selectedIndex >= 0) {
			// For simplicity, I assume searchResult items are direct URLs.
			// Adjust as needed for your actual data structure.
			window.location.href = "/r/" + searchResult[selectedIndex];
		}
	};

	const { t } = props;

	return (
		<div className='search'>
			<input
				ref={searchFieldRef}
				type='search'
				placeholder={t("search.searchfieldPlaceholder")}
				onChange={searchHandler}
				onKeyDown={onKeyDownHandler}
			/>
			<div className='search-result-wrapper'>
				<ul className='search-results'>
					{searchResult === undefined ? (
						<Skeleton />
					) : (
						<>
							{searchResult?.map((res, id) => {
								return (
									<div key={id}>
										<div key={id + "divider"} className='search-item-divider' />
										<Link key={id} to={"/r/" + res} onClick={clearSearchInput}>
											<li className={`search-item ${id === selectedIndex ? "selected" : ""}`}>
												{res || <Skeleton />}{" "}
											</li>
										</Link>
									</div>
								);
							})}
							{searchResult?.length === 0 && (
								<li className='search-item' key='no-results'>{`${t("search.noResults")}...`}</li>
							)}
						</>
					)}
				</ul>
			</div>
		</div>
	);
};

export default withTranslation()(Search);
