import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Pages
import Home from "../../pages/Home";
import Review from "../../pages/Review";

class Routing extends Component {
	render() {
		return (
			<Switch>
				<Route exact path='/' render={() => <Home />} />
				<Route path='/r/:name' render={() => <Review />} />
				<Route path='*' render={() => <Redirect to='/' />} />
			</Switch>
		);
	}
}

export default Routing;
