import React from "react";

// Components
import Logo from "./Logo/Logo";
import Search from "../Search/Search";
import LanguagePopover from "./LanguageSelector/LanguagePopover";
import { useContext } from "react";
import { ReviewContext } from "../../contexts/ReviewContext";

const Header = () => {
	const { isFrontPage } = useContext(ReviewContext);

	return (
		<div className='background-full-width'>
			<div className='header-container content-wrapper background-full-width'>
				<Logo />
				{!isFrontPage && <Search />}
				<LanguagePopover />
			</div>
		</div>
	);
};

export default Header;
