import { URL } from "./api";

export const getCompanyProfile = (id) => {
	return fetch(URL + "/organization/profile?orgId=" + id)
		.then((res) => {
			if (res.ok) {
				return res.json();
			} else {
				throw new Error("Something went wrong");
			}
		})
		.then((json) => {
			return new Promise((resolve, reject) => {
				return resolve(json);
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
